import { App } from "vue";

import FormArea from "./form-area";
import ScrollView from "./scroll-view";
import TabBar from "./tab-bar";
import UserAvatar from "./user-avatar";
import ScrollList from "./scroll-list";
import PageLoading from "./page-loading";

export * from "./form-area";
export * from "./tab-bar";
export * from "./user-avatar";
export * from "./scroll-list";
export * from "./paragraph-article";

export { FormArea, ScrollView, TabBar, UserAvatar, ScrollList };
export default function install(app: App) {
  app.component("ScrollView", ScrollView);
  app.component("ScrollList", ScrollList);
  app.component("PageLoading", PageLoading);
}
