import { PropType } from "vue";
import { CPickerColumnProps, CPickerProps } from "./type";

export const PickerColumnProps = {
  range: {
    type: Array as PropType<CPickerColumnProps["range"]>,
    default() {
      return [];
    },
  },
  rangeKey: String,
  index: {
    type: Number,
    default: 0,
  },
};

export const PickerProps = {
  mode: {
    type: String as PropType<CPickerProps["mode"]>,
    default: "selector",
  },
  title: {
    type: String,
    default: "请选择",
  },
  range: {
    type: Array as PropType<CPickerProps["range"]>,
    default() {
      return [];
    },
  },
  rangeKey: String,
  disabled: Boolean,
  modelValue: {
    type: [String, Number, Object, Array] as PropType<
      CPickerProps["modelValue"]
    >,
  },
  confirmText: {
    type: String,
    default: "确定",
  },
};
