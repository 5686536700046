import { RiefingState } from "@/store/type";

export default {
  state: {
    invite: null,
  },
  getters: {
    invite: (state: RiefingState) => state.invite,
  },
  mutations: {
    setInvite(state: RiefingState, value: RiefingState["invite"]) {
      state.invite = value;
    },
  },
};
