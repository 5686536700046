import http from "../request";
import { EntityListData } from "@/api";

export interface Invite {
  id: number;
  user_id: number | null;
  username: string;
  sex: number | null;
  mobile: string;
  is_receive: number;
  is_sign: number;
  create_time: string;
  update_time: string;
}

export async function getInviteGuestByMobileAndName(
  mobile: string,
  name: string
) {
  return http
    .get<EntityListData<Invite>>(
      `/invite?pageSize=1&filter[mobile]=${mobile}&filter[username]=${name}`
    )
    .then((res) => {
      const list = res.data.data;
      if (list.length > 0) {
        return list[0];
      }
      return null;
    });
}

export async function getInviteGuestByMobile(mobile?: string, name?: string) {
  let url = "/invite?pageSize=99";
  if (mobile) {
    url += `&filter[mobile]=${mobile}`;
  } else if (name) {
    url += `&filter[username]=${name}`;
  }
  return http.get<EntityListData<Invite>>(url).then((res) => res.data.data);
}

export async function inviteGuestSignIn(id: number) {
  return http
    .patch<Invite>(`/invite/${id}`, {
      is_sign: 1,
    })
    .then((res) => res.data);
}

export async function insertInviteGuest(
  data: Pick<Invite, "sex" | "username" | "mobile">
) {
  return http
    .post<Invite>("/invite", {
      ...data,
      is_receive: 2,
      is_sign: 1,
    })
    .then((res) => res.data);
}
